<template>
  <div class="p-root">
    <div class="full-width artists-banner">
      <img :src="icons.artists_banner" class="full-width" />
      <img :src="icons.artists_huxian" class="full-width artists-huxian" />
    </div>

    <div class="full-width column-center" style="padding: 50px 0">
      <div class="artists-content-width column">
        <!-- <span class="artists-title" style="align-self: center">“哈罗~合作者”</span> -->
        <img :src="icons.artists_en_paszko" style="width:400px;align-self:center" />
        <span class="artists-label" style="margin-top: 50px">Malgorzata PASZKO</span>

        <p class="artists-content">
          Malgorzata Paszko is an impressionist and sculptor living in Normandy as well as an artist with the Prix de
          Rome (Villa Medici). She lived in China and exhibited her works from 2016 to 2017. Besides, she conducted
          multiple exhibitions in France, Belgium, Germany, Greece, Poland, and Lebanon. Currently, some of her works
          are displayed in FRAC, Picasso Museum, National Library of France, Nicolaus Copernicus University in Torun,
          Sarajevo National Art Gallery, and Luxembourg-based Dudelange Municipal Government.
        </p>

        <p class="artists-content">Awards:</p>
        <p class="artists-content">1978: Prix de la Fondation de France (France)</p>
        <p class="artists-content">1981: Prix Salon de Montrouge, France (France)</p>
        <p class="artists-content">1986: Prix de Roma (Villa Médicis) (Italy)</p>
        <p class="artists-content">1987: Prix Lacourrière (Sculpture) (Paris)</p>
        <p class="artists-content">1992: Prix Martini (Paris)</p>
        <p class="artists-content">1999: Prix International Michelin (Paris)</p>
        <img :src="icons.artists_en_paszko1" style="margin-top: 20px;width:400px;align-self:center" />
        <img :src="icons.artists_en_paszko2" style="margin-top: 20px;width:400px;align-self:center" />

        <!-- <span class="artists-link" style="align-self: flex-end" @click="navigateTo('group35')">点此查看艺术家观点</span> -->

        <!-- <img class="artists-content-width" style="margin-top: 20px" :src="icons.artists_malgorzata_paszko_image" /> -->

        <!-- <video style="width: 800px; margin-top: 20px" :src="icons.artists_malgorzata_paszko_video" controls></video> -->

        <!-- <span class="artists-desc" style="align-self: center">Malgorzata PASZKO 马尔戈扎塔·帕斯科对“中国合作者说的一句话”</span> -->
      </div>

      <div class="artists-content-width column">
        <img :src="icons.artists_en_ronel" style="width:400px;align-self:center;margin-top:100px" />
        <span class="artists-label" style="margin-top: 20px">Christophe RONEL</span>

        <p class="artists-content">
          Christophe Ronel is a French graphic artist. Over the past three decades, he has held over 80 solo exhibitions
          at the art galleries, cultural centers, and museums in the cities all over France and other countries,
          including Paris, Lille, Montepellier, Nice, Saint-Marlo, Brussels, Casablanca, Marrakesh, Suse, Hanover, Palm
          Beach, Singapore, and Tokyo as well as the National Museum of China and Tianjin Museum.
        </p>
        <p class="artists-content">
          Currently, his works are collected by various public and private establishments in France, Belgium,
          Netherlands, Germany, Austria, Switzerland, Italy, Morocco, Lebanon, USA, Canada, Brazil, Australia,
          Singapore, Japan, and the United Arab Emirates.
        </p>

        <p class="artists-content">Awards:</p>

        <p class="artists-content">1985 : National Ocean Prize of France</p>
        <p class="artists-content">1993 : Gold Award of French Artists Salon</p>
        <p class="artists-content">1998 : First Prize of Paris Autumn Salon</p>
        <p class="artists-content">2002 : First Prize of Rouen Normandy Artist</p>
        <p class="artists-content">2004 : Rugale Michailov Award of Taylor Foundation</p>

        <img :src="icons.artists_en_ronel1" style="margin-top: 20px;width:400px;align-self:center" />
        <img :src="icons.artists_en_ronel2" style="margin-top: 20px;width:400px;align-self:center" />

        <!-- <span class="artists-link" style="align-self: flex-end" @click="navigateTo('group67')">点此查看艺术家观点</span> -->

        <!-- <img class="artists-content-width" style="margin-top: 20px" :src="icons.artists_christophe_ronel_image" /> -->

        <!-- <video style="width: 800px; margin-top: 20px" :src="icons.artists_christophe_ronel_video" controls></video> -->

        <!-- <span class="artists-desc" style="align-self: center">Christophe RONEL 克里斯托夫·罗内尔对“中国合作者说的一句话”</span> -->
      </div>

      <div class="artists-content-width column">
        <img :src="icons.artists_en_renard" style="width:400px;align-self:center;margin-top:100px" />
        <span class="artists-label" style="margin-top: 20px">Emmanuelle RENARD</span>

        <p class="artists-content">
          Emmanuelle Renard : As a painter, designer, sculptor, and ceramist, she is an important figurative artist in
          France. She has been holding art exhibitions in France and other countries since 1986, and the cities include
          Paris, Hamburg, Frankfurt, Karlsruhe, London, Brussels, Copenhagen, Geneva, Basel, Stockholm, Chicago, Los
          Angeles, and Beirut. She has been drawing the attention of numerous collectors in France and the rest of the
          world for over three decades.
        </p>
        <p class="artists-content">
          Her paintings can be defined as surreal expressionism, vice versa, for they are strong, powerful, and
          expressive. Her paintings feature vivid articles, eccentric figures, impossible scenarios, and pure colors. If
          it’s necessary to find an origin for such paintings, we will mention Baroque artists, Goya, Shamsuddin,
          Soutine, Chagall, and Rebeyrolle.
        </p>

        <img :src="icons.artists_en_renard1" style="margin-top: 20px;width:400px;align-self:center" />
        <img :src="icons.artists_en_renard2" style="margin-top: 20px;width:400px;align-self:center" />

        <!-- <span class="artists-link" style="align-self: flex-end" @click="navigateTo('group89')">点此查看艺术家观点</span> -->

        <!-- <img class="artists-content-width" style="margin-top: 20px" :src="icons.artists_emmanuelle_renard_image" /> -->

        <!-- <video style="width: 800px; margin-top: 20px" :src="icons.artists_emmanuelle_renard_video" controls></video> -->

        <!-- <span class="artists-desc" style="align-self: center">Emmanuelle RENARD 埃马纽埃尔·热娜徳对“中国合作者说的一句话”</span> -->
      </div>

      <div class="artists-content-width column">
        <img :src="icons.artists_en_sainrapt" style="width:400px;align-self:center;margin-top:100px" />
        <span class="artists-label" style="margin-top: 20px">Sophie SAINRAPT</span>

        <p class="artists-content">
          Sophie Sainrapt : She is a painter and sculptor. Her paintings are deeply inspired by literature. So far, she
          has held about 150 exhibitions in France, China, Belgium, Italy, South Korea, Japan, and USA. Besides, she
          works for the Senate of France. By 2016, she had been conducting ArtSénat, a contemporary art event, at the
          Orangerie and Luxembourg Garden for ten years.
        </p>

        <img :src="icons.artists_en_sainrapt1" style="margin-top: 20px;width:400px;align-self:center" />
        <img :src="icons.artists_en_sainrapt2" style="margin-top: 20px;width:400px;align-self:center" />

        <!-- <span class="artists-link" style="align-self: flex-end" @click="navigateTo('group12')">点此查看艺术家观点</span> -->

        <!-- <img class="artists-content-width" style="margin-top: 20px" :src="icons.artists_sophie_sainrapt_image" /> -->

        <!-- <video style="width: 800px; margin-top: 20px" :src="icons.artists_sophie_sainrapt_video" controls></video> -->

        <!-- <span class="artists-desc" style="align-self: center">Sophie SAINRAPT 苏菲·圣拉对“中国合作者说的一句话”</span> -->
      </div>

      <div class="artists-content-width column">
        <img :src="icons.artists_en_foldi" style="width:400px;align-self:center;margin-top:100px" />
        <span class="artists-label" style="margin-top: 20px">Augusto FOLDI</span>

        <p class="artists-content">
          Augusto Foldi is an Italian painter of Hungarian ancestry. He lived in North France in late 1960s, finishing
          his first painting. He settled in Paris in 1975, studying oil painting technology through symbols and
          metaphors. In 1979, he held his first solo exhibition in Paris. Since then, he has conducted more than 20 art
          exhibitions in France.
        </p>

        <img :src="icons.artists_en_foldi1" style="margin-top: 20px;width:400px;align-self:center" />
        <img :src="icons.artists_en_foldi2" style="margin-top: 20px;width:400px;align-self:center" />

        <!-- <span class="artists-link" style="align-self: flex-end" @click="navigateTo('group14')">点此查看艺术家观点</span> -->

        <!-- <img class="artists-content-width" style="margin-top: 20px" :src="icons.artists_augusto_foldi_image" /> -->

        <!-- <video style="width: 800px; margin-top: 20px" :src="icons.artists_augusto_foldi_video" controls></video> -->

        <!-- <span class="artists-desc" style="align-self: center">Augusto FOLDI 奥古斯都·弗乐迪对“中国合作者说的一句话”</span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  methods: {
    navigateTo(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>

<style scoped>
.artists-banner {
  position: relative;
  display: block;
}
.artists-huxian {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.artists-title {
  font-size: 22px;
  color: #282828;
  letter-spacing: 0;
}
.artists-label {
  font-weight: bold;
  font-size: 18px;
  color: #282828;
  letter-spacing: 0;
}
.artists-content {
  font-size: 16px;
  color: #282828;
  letter-spacing: 0;
  text-indent: 25px;
}
.artists-link {
  cursor: pointer;
  font-size: 16px;
  color: #282828;
  font-weight: bold;
  letter-spacing: 0;
  color: #154a80;
  text-decoration: underline;
}
.artists-desc {
  margin-top: 10px;
  font-size: 16px;
  color: #282828;
  font-weight: bold;
  letter-spacing: 0;
  color: #154a80;
}
.artists-content-width {
  width: 800px;
}
</style>